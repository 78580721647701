<template>
  <div class="main-full-content">
    <el-form ref="form" :model="queryForm" label-width="80px" size="medium">
      <el-card class="box-card">
          <el-row>
        <el-col :xs="24" :sm="12" :md="12" :lg="6"></el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form-item label-width="0px">
            <el-button
              size="medium"
              icon="el-icon-plus"
              type="primary"
              @click="handleAdd"
              v-if="buttonShowList.add"
              >新增</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
        <f-table
          v-loading="loading"
          ref="GTable"
          border
          size="medium"
          row-key="id"
          class="c-th-has-bgcolor"
          :columns="columns"
          :data="tableData"
          :cell-style="{ 'text-align': 'center' }"
          tableHeight="74vh"
          @selection-change="handleSelectionChange"
          @sort-change="handleTableChange"
        >
          <template v-slot="scope">
            <template v-if="scope.column.property === 'action'">
              <el-button type="text" @click="handleEdit(scope.row)"
                         v-if="buttonShowList.edit"
                >编辑</el-button
              >
              <el-button type="text" @click="handleDel(scope.row)"
                         v-if="buttonShowList.delB"
                >删除</el-button
              >
              <el-button
                type="text"
                @click="handleDefault(scope.row)"
                v-if="scope.row.isDefault === '0' && buttonShowList.setD"
                >设为默认</el-button
              >
            </template>
            <template v-else-if="scope.column.property === 'isBussiness'">
              <span>{{parseDict(scope.row.isBussiness, "is_bussiness")}}</span>
            </template>
            <template v-else-if="scope.column.property === 'isDefault'">
              <span>{{ parseDict(scope.row.isDefault, "is_default") }}</span>
            </template>
          </template>

        </f-table>
        <el-row class="f-text-right f-p-v-8">
          <el-pagination
                  v-bind="ipagination"
                  layout="total, sizes, prev, pager, next, jumper"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange">
          </el-pagination>
        </el-row>
      </el-card>

    </el-form>

    <el-dialog
      :title="form.id ? '编辑地址' : '新增地址'"
      v-if="dialogFormVisible"
      :visible.sync="dialogFormVisible"
      width="40%"
      center
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules" ref="form">
        <el-row class="inputs_row" :gutter="15">
          <el-col :span="12">
            <el-form-item
              label="联系人"
              prop="contact"
              :label-width="formLabelWidth"
              :rules="rules.contact"
            >
              <el-input v-model="form.contact" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式" prop="tel" :label-width="formLabelWidth" :rules="rules.tel">
              <el-input v-model="form.tel" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="公司名称"
              prop="company"
              :label-width="formLabelWidth"

            >
              <el-input v-model="form.company" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="国家" prop="country" :label-width="formLabelWidth">
              <f-select
                v-model="form.country"
                :fatherVal="form.country"
                :dict="'bd_country'"
                @change="initStateList"
                :isNeed="form.country"
                v-if="fsee"
              >
              </f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="州/省"  :label-width="formLabelWidth" prop="state" :rules="rules.state">
<!--              <el-select-->
<!--                class="full_width"-->
<!--                v-model="form.state"-->
<!--                placeholder="请选择"-->
<!--                @change="initCityList"-->
<!--                filterable-->
<!--                clearable-->
<!--              >-->
<!--                <el-option-->
<!--                  v-for="(item, index) in statelist"-->
<!--                  :key="index"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"-->
<!--                ></el-option>-->
<!--              </el-select>-->
                <el-input v-model="form.state" maxlength="20" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="城市"  :label-width="formLabelWidth" prop="city" :rules="rules.city">
<!--              <el-select-->
<!--                class="full_width"-->
<!--                v-model="form.city"-->
<!--                placeholder="请选择"-->
<!--                filterable-->
<!--                clearable-->
<!--              >-->
<!--                <el-option-->
<!--                  v-for="(item, index) in citylist"-->
<!--                  :key="index"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"-->
<!--                ></el-option>-->
<!--              </el-select>-->
                <el-input v-model="form.city" maxlength="20" show-word-limit></el-input>

            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="地址行1"
              prop="address"
              :label-width="formLabelWidth"
              :rules="rules.address"
            >
              <el-input v-model="form.address" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                    label="地址行2"
                    prop="address2"
                    :label-width="formLabelWidth"
            >
              <el-input v-model="form.address2" autocomplete="off" show-word-limit maxlength="255" style="padding:0 40px 0 0"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="地址类型"
              prop="isBussiness"
              :label-width="formLabelWidth"
            >
              <el-radio v-model="form.isBussiness" label="1">商业地址</el-radio>
              <el-radio v-model="form.isBussiness" label="0">住宅地址</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否需要尾板车" label-width="110px" prop="isDischarge">
              <el-radio-group v-model="form.isDischarge">
                <el-radio label="1" >是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否需要预约" label-width="110px" prop="isYuyue">
              <el-radio-group v-model="form.isYuyue">
                <el-radio label="1" >是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="form.isYuyue == '1'">
            <el-form-item label="预约类型" prop="yyType" :label-width="formLabelWidth">
              <f-select v-model="form.yyType" :dict="'address_yuyue'" :is-need="form.yyType" :father-val="form.yyType"></f-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮编" prop="zipcode" :label-width="formLabelWidth">
              <el-input v-model="form.zipcode" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="dialogFormVisible = false"
          >取 消</el-button
        >
        <el-button
          size="medium"
          type="primary"
          @click="saveAddress"
          :disabled="btnDisable"
          >确 定</el-button
        >
      </div>
    </el-dialog>


  </div>
</template>

<script>
import { putAction, deleteAction, postAction, getAction } from "@/api/manage";
import { getUrlKey } from "@/utils/route";
import { mobilePattern } from "@/utils/pattern";
import QueryMixins from "../../../mixins/QueryMixins";
import { getcityList, getstateList } from "../../../api/manage";
import {noZhongwenValidator} from "../../../utils/validator";
export default {
  name: "AddressModule",
  mixins: [QueryMixins],
  components: {},
  data() {
     const hasTwoCapitals = (rule, value, callback) => {
      const val = (value !== undefined && value !== null && (value + '').trim())
      console.log(val,'val');
      
    if (this.form.country == '美国' ||this.form.country =='加拿大') {
      if(val && !(/[A-Z]{2,}/.test(val))){
         this.$message.error("美国与加拿大商业地址与私人地址洲名填写州名缩写代码，仅能填写2字符且为英文大写")
 callback(new Error(''));
      }
       
    }
    callback();
    };
    const noZhongwenValidatorSelf = (rule, value, callback) => {
      const val = (value !== undefined && value !== null && (value + '').trim()) || ''
    if (val && val.length > 0 && this.form.country !== '中国' && this.form.country !=='日本') {
        for (var i = 0; i < val.length; i++) {
            let v = val.substr(i, 1);
            if (/^[\u4e00-\u9fa5]{0,}$/.test(v)) {
                callback(new Error('不能有中文'));
            }
        }
    }
    callback();
    };
    return {
      dictCodes: ["is_bussiness", "is_default"],
      visible: false,
      formLabelWidth: "70px",
      row: {},
      form: {},
      //控制弹框显示
      dialogFormVisible: false,
      //表单校验
      rules: {
        fsee: false,
        contact: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
            {validator:noZhongwenValidatorSelf,trigger: 'change'}
        ],
        tel: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
            {validator:noZhongwenValidator,trigger: 'change'}
          // {
          //   pattern: mobilePattern,
          //   message: "请输入正确的联系方式",
          //   trigger: "change",
          // },
        ],
        isBussiness: [
          { required: true, message: "请选择是否商业地址", trigger: "change" },
        ],
        isDischarge: [
          { required: true, message: "请选择有无卸货平台", trigger: "change" },
        ],
        isYuyue: [
          { required: true, message: "请选择需要预约", trigger: "change" },
        ],
        yyType: [
          { required: true, message: "请选择预约类型", trigger: "change" },
        ],
        country: [{ required: true, message: "请选择国家", trigger: "change" }],
        // state: [{ validator: noZhongwenValidator,  trigger: "change" }],
        // city: [{ validator: noZhongwenValidator,  trigger: "change" }],
        address: [
          { required: true, message: "请输入地址行1", trigger: "blur" },
          { max: 255, message: "长度不能超过255", trigger: "change" },
            {validator:noZhongwenValidatorSelf,trigger: 'change'}
        ],
        zipcode: [
          { required: true, message: "请输入邮编", trigger: "blur" },
          { max: 50, message: "长度不能超过50", trigger: "change" },
            {validator:noZhongwenValidator,trigger: 'change'}
        ],
          state:[
              {validator:noZhongwenValidator,trigger: 'change'}
          ]
          ,city:[
              {validator:noZhongwenValidator,trigger: 'change'}
          ]
            ,state:[
              {validator:hasTwoCapitals,trigger: 'change'}
          ]
      },
      columns: [
        {
          prop: "action",
          label: "操作"
        },
        {
          prop: "contact",
          label: "联系人",
          sortable: "custom",
        },
        {
          prop: "tel",
          label: "联系方式",
          sortable: "custom",
        },
        {
          prop: "company",
          label: "公司名称",
          sortable: "custom",
        },
        {
          prop: "country",
          label: "国家",
          sortable: "custom",
        },
          {
              prop: "state",
              label: "州",
          },
          {
              prop: "city",
              label: "城市",
          },
        {
          prop: "address",
          label: "详细地址",
          sortable: "custom",
        },
        {
          prop: "isBussiness",
          label: "地址类型",
          sortable: "custom",
        },
        {
          prop: "zipcode",
          label: "邮编",
          sortable: "custom",
        },
        {
          prop: "isDefault",
          label: "是否默认",
          sortable: "custom",
        },
        {
          prop: "createTime",
          label: "创建时间",
          sortable: "custom",
        },
      ],
      url: {
        list: "/customer/receivingAddress/list",
        deleteBatch: "",
        delByid: "/customer/receivingAddress/delete",
        setDefault: "/customer/receivingAddress/setDefault",
        add: "/customer/receivingAddress/add",
        edit: "/customer/receivingAddress/edit",
        getById: "/customer/receivingAddress/getById",
      },
      span: {
        type: Number,
        default: 8,
      },
      countrylist: [],
      statelist: [],
      citylist: [],
      btnDisable: false,
    };
  },
  methods: {
    initStateList(val) {
      this.statelist = [];
      this.citylist = [];
      if (val) {
        getstateList({ cnName: val }).then((res) => {
          if (res.success) {
            for (var i = 0; i < res.result.records.length; i++) {
              let obj = {};
              obj.value = res.result.records[i].cname;
              obj.label =
                res.result.records[i].code + "-" + res.result.records[i].cname;
              this.statelist.push(obj);
            }
          }
        });
      } else {
        // this.form.city = '';
        // this.form.state = '';
        // this.form.bdState = '';
        // this.form.bdCity = '';
      }
    },
    initCityList(val) {
      this.citylist = [];
      if (val) {
        getcityList({ cname: val }).then((res) => {
          if (res.success) {
            for (var i = 0; i < res.result.records.length; i++) {
              let obj = {};
              obj.value = res.result.records[i].code;
              obj.label =
                res.result.records[i].code + "-" + res.result.records[i].cname;
              this.citylist.push(obj);
            }
          }
        });
      } else {
      }
    },
    hasTwoCapitals(str) {
      const regex = /[A-Z]{2,}/;
      return str.length == 2 && regex.test(str);
    },
    saveAddress() {
      // if((this.form.country == '美国' ||this.form.country =='加拿大') &&!this.hasTwoCapitals(this.form.state)){
      //   this.$message.error("美国与加拿大商业地址与私人地址洲名填写州名缩写代码，仅能填写2字符且为英文大写")
      //   return
      // }
      //保存地址
      //获取页面上的客户id
      this.btnDisable = true;
      var cusId = getUrlKey("id");
      //form表单有id，则是编辑
      this.form.cusId = cusId;
      //校验通过才提交
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            putAction(this.url.edit, this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  //关闭弹窗
                  this.dialogFormVisible = false;
                  this.handleQuery();
                } else {
                  this.btnDisable = false;
                }
              })
              .catch((err) => {
                this.btnDisable = false;
              });
          } else {
            postAction(this.url.add, this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success(res.message);
                  //关闭弹窗
                  this.dialogFormVisible = false;
                  this.handleQuery();
                } else {
                  this.btnDisable = false;
                }
              })
              .catch((err) => {
                this.btnDisable = false;
              });
          }
        } else {
          this.btnDisable = false;
        }
      });

      //反之是新增
    },

    handleEdit(row) {
      this.fsee = false;
      this.row = row;
      //显示弹窗
      //给form对象id赋值
      this.form.id = row.id;
      //根据id查询
      getAction(this.url.getById, { id: row.id })
        .then((res) => {
          if (res.success) {
            this.form = res.result;
            this.fsee = true;
          }
        })
      this.initStateList(this.form.country);
      this.initCityList(this.form.state);
      this.dialogFormVisible = true;
      this.btnDisable = false;
    },
    //点击新增，显示弹窗
    handleAdd() {
      this.fsee = false;
      this.form = {};
      this.fsee = true;
      //判断是否超过10
      let total = this.ipagination.total;
      if (total >= 40) {
        this.$message.warning("收货地址最多40个，无法新增了亲");
      } else {
        this.dialogFormVisible = true;
      }
      this.btnDisable = false;
    },
    handleDel(row) {
      this.$confirm(`确定删除${row.contact}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.delByid, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        });
    },
    handleDefault(row) {
      this.$confirm(`确定将${row.contact}设为默认？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          putAction(this.url.setDefault + "?id=" + row.id, { id: row.id }).then(
            (res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.handleQuery();
            }
          );
        })
    },
    handleBatApproval() {
      var cname = this.selectedRowKeys;
      this.$confirm(`确定将【${cname}】解冻?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(
            this.url.batchjiedong + "?ids=" + this.selectedRowKeys.join(",")
          ).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleQuery();
          });
        })
        .catch(() => {
          this.$message.error(err.message);
        });
    },
    handleBatDel() {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.deleteBatch, {
            ids: this.selectedRowKeys.join(","),
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        })
        .catch(() => {
          this.$message.error(err.message);
        });
    },
    handleThaw(row) {
      this.$confirm(`确定解冻${row.name}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      })
        .then(() => {
          deleteAction(this.url.jiedong, { id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.handleSearch();
          });
        })
        .catch(() => {
          this.$message.error(err.message);
        });
    },
  },
  created() {
    this.queryForm.cusId = this.$route.query.id;
  },
  computed: {
    buttonShowList() {
      return {
        'add':this.$btnIsShow('customer/modules/AddressModule','0','新增'),
        'edit':this.$btnIsShow('customer/modules/AddressModule','1','编辑'),
        'delB':this.$btnIsShow('customer/modules/AddressModule','1','删除'),
        'setD':this.$btnIsShow('customer/modules/AddressModule','1','设为默认'),


      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
