<template>
  <el-card class="box-card">

    <!-- <el-card class="box-card last_card"> -->
    <el-form :model="form" :form="form" :inline="true" :rules="rules" ref="form">
      <el-row class="volume_top nowrap_label">
        <el-col :span="4">
          <el-form-item label="实际件数" style="min-width: 80px; display: flex" prop="realCtn">
            <el-input ref="realt" v-model="form.realCtn" style="width: 140px"
                      placeholder="请输入实际总件数"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="过磅重" style="display: flex" prop="realKg">
            <el-input v-model="form.realKg" placeholder="请输入过磅重" type="number"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="仓库备注" style="display: flex" prop="storehouseRemark">
            <el-input type="textarea" :rows="2"
                      v-model="form.storehouseRemark" placeholder="请输入仓库备注" maxlength="400"
                      show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item style="min-width: 80px; display: flex" label="入库库位" label-width="70px">
            <el-select v-model="form.location" @change="locationChange" clearable filterable>
              <el-option v-for="(item,index) in allLocation"
                         :key="index"
                         :value="item.warehouseLocationCode"
                         :label="item.label"
              >

              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item style="min-width: 80px; display: flex" label="入库仓库" label-width="70px">
            <el-input type="text" v-model="form.warehouse"/>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item style="min-width: 80px; display: flex" label="临时库位" label-width="70px">
            <el-select v-model="form.tempWareHouseLocations" filterable clearable>
              <el-option v-for="(item,index) in allLocation"
                         :key="index"
                         :value="item.warehouseLocationCode"
                         :label="item.label">

              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <!--        <el-button type="primary" size="medium" @click="handleAdd2" :disabled="isShow"-->
        <!--            >添加地址</el-button>-->
        <!--          仓库备注-->
        <!--     <span style="margin-left: 30px">仓库备注： <span>{{order.storehouseRemark}}</span></span>-->
        <!--                <el-col style="width: 120px">-->

        <!--                </el-col>-->
      </el-row>
      <div class="f-p-b-15 flex_wrap_between button_bottom_o">
        <div>
          <el-button type="primary" size="small" @click="handleAdd" style="float: left;margin-left: 7px">新增一行
          </el-button>
          <el-button type="primary" size="small" icon="check"
                    style="float: left;margin-left: 7px"
                    @click="saveBatch" v-if="buttonShowList.bc">
            {{ buttonContent }}
          </el-button>
          <el-button type="primary" size="small" icon="check" @click="deleteBatch"
                    style="float: left;margin-left: 7px"
                    v-if="buttonShowList.plsc"
                    :disabled="selectedRowKeys.length === 0">批量删除
          </el-button>
          <el-button type="primary" size="small" icon="check" @click="downloadVolumeList"
                    style="float: left;margin-left: 7px"
                    v-if="buttonShowList.dccj"
          >导出材积
          </el-button>
           <el-button style="margin-left: 10px" @click="changeWarehouseFn" type="primary" 
                                size="mini" :disabled='!selectionRows.length'
                                 v-if="buttonShowList.xgszc"
                      >修改所在仓
                      </el-button>
                       <el-button style="margin-left: 10px" @click="getImgList" type="primary"   v-if="buttonShowList.shtp"
                                size="mini"
                      >查看收货图片
                      </el-button>
        </div>
        <div class="flex_center">
          <span>订单编号：{{ $route.query.orderNo }}</span>
          <span class="f-m-l-20">计费数量：{{ countFeeNum || '' }}</span>
          <span class="f-m-l-20">货物所在仓:{{form.allVolumeLocations || '/'}}</span>
          <span class="f-m-l-20">到仓时间：</span>
          <el-date-picker v-if="buttonShowList.bcdcsj"
            v-model="newCargoArrivedTime"
            type="datetime"
            size="small"
            placeholder="到仓时间"
            default-time="00:00:00"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 180px"
          >
          </el-date-picker>
          <span v-else>{{ order.cargoArrivedStart || '' }}</span>
          <el-button class="f-m-l-20" type="primary" size="small" icon="check" @click="handleUpdateCargoArrivedTime"
                    style="float: left;margin-left: 7px"
                    v-if="buttonShowList.bcdcsj"
          >保存到仓时间
          </el-button>
        </div>
      </div>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-table v-loading="loading"
                    ref="GGTable"
                    border
                    show-summary
                    :summary-method="getSummaries"
                    size="medium"
                    row-key="id"
                    class="c-th-has-bgcolor total_top_table"
                    :data="form.data"
                    max-height="600"
                    :key="tableRefresh"
                    :header-cell-style="headerClass"
                    @selection-change="handleSelectionChange">
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column prop="key" label="编号" width="32">
              <template slot-scope="scope">
                <span>{{ scope.row.key }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="length" label="长(CM)" width="100">
              <!-- 标头需要加必填标识 -->
              <template slot="header">
                <template>
                  <span class="is_require_form">长(CM)</span>
                </template>
              </template>
              <template slot-scope="scope">
                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right"
                              :rules="rules.sanweiCanNotNull" :prop="'data.'+ scope.$index + '.length'">
                  <el-input size="small" v-model="scope.row.length" placeholder="请输入" :disabled="scope.row.volumeLocation==='转仓中'"

                            @change="e => handleChange(e, scope.row.key, 'length',scope.$index)"
                            class="classi"
                            type="number"
                            @keydown.enter.native="nextFocus(scope.$index,'length')"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="width" label="宽(CM)" width="100">
              <!-- 标头需要加必填标识 -->
              <template slot="header">
                <template>
                  <span class="is_require_form">宽(CM)</span>
                </template>
              </template>
              <template slot-scope="scope">
                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right"
                              :rules="rules.sanweiCanNotNull" :prop="'data.'+ scope.$index + '.width'"
                              :key="'data.'+ scope.$index + '.width'">
                  <el-input size="small" v-model="scope.row.width" placeholder="请输入"
                            @change="e => handleChange(e, scope.row.key, 'width',scope.$index)"
                            class="classi"
                            @keydown.enter.native="nextFocus(scope.$index,'width')"
                            type="number"
                            :disabled="scope.row.volumeLocation==='转仓中'"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="height" label="高(CM)" width="100">
              <!-- 标头需要加必填标识 -->
              <template slot="header">
                <template>
                  <span class="is_require_form">高(CM)</span>
                </template>
              </template>
              <template slot-scope="scope">
                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right"
                              :rules="rules.sanweiCanNotNull" :prop="'data.'+ scope.$index + '.height'"
                              :key="'data.'+ scope.$index + '.height'">
                  <el-input size="small" v-model="scope.row.height" placeholder="请输入"
                            @change="e => handleChange(e, scope.row.key, 'height',scope.$index)"
                            class="classi"
                            @keydown.enter.native="nextFocus(scope.$index,'height')"
                            :disabled="scope.row.volumeLocation==='转仓中'"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="number" label="总件数/卡板" width="100">
              <!-- 标头需要加必填标识 -->
              <template slot="header">
                <template>
                  <span class="is_require_form">总件数/卡板</span>
                </template>
              </template>
              <template slot-scope="scope">
                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right"
                              :rules="rules.number" :prop="'data.'+ scope.$index + '.number'"
                              :key="'data.'+ scope.$index + '.number'">
                  <el-input size="small" v-model.number="scope.row.number" placeholder="请输入"
                            @change="e => handleChange(e, scope.row.key, 'number',scope.$index)"
                            class="classi"
                            type="number"
                            :disabled="scope.row.volumeLocation==='转仓中'"
                            @keydown.enter.native="nextFocus(scope.$index,'number')"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="weight" label="实重(KG)" width="100">
              <template slot="header">
                <template>
                  <span class="is_require_form">实重(KG)</span>
                </template>
              </template>
              <template slot-scope="scope">
                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right"
                              :rules="rules.sanweiCanNotNull" :prop="'data.'+ scope.$index + '.weight'"
                              :key="'data.'+ scope.$index + '.weight'">
                  <el-input size="small" v-model="scope.row.weight" placeholder="请输入"
                            @change="e => handleChange(e, scope.row.key, 'weight',scope.$index)"
                            class="classi"
                            type="number"
                            @keydown.enter.native="nextFocus(scope.$index,'weight')" :disabled="scope.row.volumeLocation==='转仓中'"></el-input>
                </el-form-item>

              </template>
            </el-table-column>
            <el-table-column prop="volume" label="实体(CBM)" width="100">
              <template slot-scope="scope">
                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right"
                              :rules="rules.sanweiCanNull" :prop="'data.'+ scope.$index + '.volume'"
                              :key="'data.'+ scope.$index + '.volume'">
                  <el-input size="small" v-model="scope.row.volume" placeholder="请输入"
                            @change="e => handleChange(e, scope.row.key, 'volume',scope.$index)"
                            class="classi"
                            type="number"
                            @keydown.enter.native="nextFocus(scope.$index,'volume')" :disabled="scope.row.volumeLocation==='转仓中'"></el-input>
                </el-form-item>

              </template>
            </el-table-column>
            <el-table-column prop="largeInfo" label="超大件" width="130">
              <template slot-scope="scope">
                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right"
                              :prop="'data.'+ scope.$index + '.largeInfo'"
                              :key="'data.'+ scope.$index + '.largeInfo'">
                  <el-select
                      :ref="`selectLargeInfo.${scope.$index}`"
                      class="classi" :dict="'volume_large_info'" v-model="scope.row.largeInfo"
                      :is-need="scope.row.largeInfo"
                      @changet="e => handleChange(e, scope.row.key, 'largeInfo',scope.$index)"
                      @keydown.enter.native="nextFocus(scope.$index,'largeInfo')"
                      clearable filterable :disabled="scope.row.volumeLocation==='转仓中'">
                    <el-option v-for="(i,d) in GLOBAL.dictListMap['volume_large_info']" :key="d" :value="i.value"
                               :label="i.label"></el-option>
                  </el-select>
                  <!--                                    <f-select class="classi" :dict="'volume_large_info'" v-model="scope.row.largeInfo" :is-need="scope.row.largeInfo"-->
                  <!--                                              @changet="e => handleChange(e, scope.row.key, 'largeInfo',scope.$index)"-->
                  <!--                                              @keydown.enter.native="nextFocus(scope.$index,'largeInfo')"></f-select>-->
                  <!--                                    <el-input size="small" v-model="scope.row.volume" placeholder="请输入"-->
                  <!--                                              @change="e => handleChange(e, scope.row.key, 'volume',scope.$index)"-->
                  <!--                                              class="classi"-->
                  <!--                                              type="number"-->
                  <!--                                              @keydown.enter.native="nextFocus(scope.$index,'volume')"></el-input>-->
                </el-form-item>

              </template>
            </el-table-column>
            <el-table-column prop="largeInfo" label="材积状态" width="130">
              <template slot-scope="scope">
                <el-form-item style="display: flex" class="margin_tb_10 no_padding_right"
                              :prop="'data.'+ scope.$index + '.volumeStatus'"
                              :key="'data.'+ scope.$index + '.volumeStatus'">
                  <el-select
                          :ref="`selectLargeInfo.${scope.$index}`"
                          class="classi" :dict="'volume_status'" v-model="scope.row.volumeStatus"
                          :is-need="scope.row.volumeStatus"
                          @keydown.enter.native="nextFocus(scope.$index,'volumeStatus')"
                          clearable filterable >
                    <el-option v-for="(i,d) in GLOBAL.dictListMap['volume_status']" :key="d" :value="i.value"
                               :label="i.label"></el-option>
                  </el-select>
                </el-form-item>

              </template>
            </el-table-column>
            <el-table-column prop="volumeWeight" label="体积重(KG)" width="100">
              <template slot="header">
                <span>体积重(KG)</span>
                <el-popover placement="top" trigger="hover" width="400" content="">
                  <p>
                    体积重 = 实体*系数，美转墨(快船)渠道系数200，其他渠道系数167<br/>
                    总体积重 = 每行体积重之和<br/>
                  </p>
                  <i class="el-icon-question" slot="reference"></i>
                </el-popover>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.volumeWeight }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="weightVolume" label="重量体(CBM)" width="100">
              <template slot="header">
                <span>重量体(CBM)</span>
                <el-popover placement="top" trigger="hover" width="400" content="">
                  <p>
                    重量体 = 实重/重量体比例<br/>
                    不同国家，重量体比例不同，美国加拿大363，欧洲350等<br/>
                    重量体比例维护在国家业务系数菜单下<br/>
                    实重、重量体比例二者有一为空，重量体为空<br/>
                    总重量体 = 每行重量体之和<br/>
                  </p>
                  <i class="el-icon-question" slot="reference"></i>
                </el-popover>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.weightVolume }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="countFeeWeight" label="单箱计费重(KG)" width="150" key="countFeeWeight"
                             v-if="showColume">

              <template slot-scope="scope">
                <span>{{ scope.row.countFeeWeight }}</span>
              </template>
            </el-table-column>
            <!--<el-table-column prop="countFeeVolume" label="计费体(CBM)" width="100">-->
            <!--<template slot="header">-->
            <!--<span>计费体(CBM)</span>-->
            <!--<el-popover placement="top" trigger="hover" width="400" content="" >-->
            <!--<p>-->
            <!--计费体 = 实体VS重量体取大者<br/>-->
            <!--总计费体: 每行计费体之和<br/>-->
            <!--计费方式为CBM则计费数量取总实体与总重量体中大者<br/>-->
            <!--存在某行实重为空，计费数量 = (过磅重VS重量体比例)VS总实体取大者<br/>-->
            <!--</p>-->
            <!--<i class="el-icon-question" slot="reference"></i>-->
            <!--</el-popover>-->
            <!--</template>-->
            <!--<template slot-scope="scope">-->
            <!--<span>{{scope.row.countFeeVolume}}</span>-->
            <!--</template>-->
            <!--</el-table-column>-->
              <el-table-column prop="volumeLocationId" label="货物所在仓" width="180px">
                           <template slot-scope="scope">
                              <el-form-item style="display: flex" class="margin_tb_10 no_padding_right" 
                                            :rules="rules.volumeLocationId" :prop="'data.'+ scope.$index + '.volumeLocationId'"
                                            :key="'data.'+ scope.$index + '.volumeLocationId'">
                                              <f-select :data-list="deliveryPointDictList" :is-need="scope.row.volumeLocationId" v-model="scope.row.volumeLocationId" autocomplete="off"
                                              @changetAllData="(e)=>changetLocations(e,scope.$index)" :disabled="scope.row.volumeLocation==='转仓中'" v-if="scope.row.volumeLocation!=='转仓中'"></f-select>
                                              <span v-else>{{ scope.row.volumeLocation }}</span>
                              </el-form-item>
                              
                            </template>
              </el-table-column>
               <el-table-column prop="busNo" label="托盘编号/散货编号" width="150" key="busNo">
              <template slot-scope="scope">
                <span>{{ scope.row.busNo }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间">
              <template slot-scope="scope">
                <span>{{ scope.row.createTime }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="createBy" label="录入人">
              <template slot-scope="scope">
                <span>{{ scope.row.createBy }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="updateTime" label="修改时间">
              <template slot-scope="scope">
                <span>{{ scope.row.updateTime }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="操作">
              <template slot-scope="scope">
                <el-button type="text" @click="handleDel(scope.row.key)" :disabled="scope.row.volumeLocation==='转仓中'">删除</el-button>
                <el-button type="text" @click="handleCopy(scope.row)">复制</el-button>
              </template>
            </el-table-column>

          </el-table>
        </el-col>
      </el-row>
      <div class="content">
        <div class="cont" v-for="(item, index) in imgList" :key="index">
          <el-image :src="item" fit="fill" :preview-src-list="imgList" class="cont_box">
            <div slot="error" class="image-slot" style="font-size: 20px;margin-top: 300px;">
              <i class="el-icon-picture-outline">暂无图片</i>
            </div>
          </el-image>
          <div>
              <span style="margin-right: 8px;">上传人：{{fileList[index].createBy}}</span>
                    <span>上传时间：{{fileList[index].createTime}}</span>
          </div>
            
        </div>

      </div>
    </el-form>
 <el-dialog
      title="修改所在仓"
      :visible="visible"
      width="30%"
      append-to-body
      custom-class="c-custom-dialog"
      :before-close="handleClose"
    >
      <el-form
        :model="formWarehouse"
        :rules="rulesWarehouse"
        ref="formWarehouse"
        label-width="94px"
        size="medium"
      >
      <el-form-item prop="volumeLocationId" label="货物所在仓:">
        <f-select :data-list="deliveryPointDictList" :is-need="formWarehouse.volumeLocationId" v-model="formWarehouse.volumeLocationId" autocomplete="off" @changetAllData="changetLocation"></f-select>
            </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="medium" @click="handleClose">取消</el-button>
        <el-button type="primary" size="medium" @click="changeWarehouse"
          >确定</el-button
        >
      </span>
    </el-dialog>


     <el-dialog
      title="收货图片"
      :visible="imgVisible"
      width="30%"
      append-to-body
      custom-class="c-custom-dialog"
      :before-close="()=>imgVisible=false"
    >
    <div style="margin-bottom:10px">开箱验货图片</div>
     <div class="take_img_box" v-if="takeImgList.openFileList && takeImgList.openFileList.length">
      <img :src="item.url" alt="" v-for="(item,index) in takeImgList.openFileList" :key="index">
     </div>
     <div v-else style="color: red;">暂无</div>
      <div style="margin: 10px 0;">收货场景图片</div>
      <div class="take_img_box" v-if="takeImgList.recFileList && takeImgList.recFileList.length">
      <img :src="item.url" alt="" v-for="(item,index) in takeImgList.recFileList" :key="index">
     </div>
     <div v-else style="color: red;">暂无</div>
      <span slot="footer">
        <el-button type="primary" size="medium" @click="()=>imgVisible=false"
          >关闭</el-button
        >
      </span>
    </el-dialog>

  </el-card>
</template>

<script>


import {validateCmb, validateCtn, validateCtn2} from "../../../utils/validator";
import draggable from 'vuedraggable'

import {getAction, httpAction, putAction} from "@/api/manage";
import {postAction} from "../../../api/manage";
    import QueryMixins from "@/mixins/QueryMixins.js";
export default {
  name: "VolumeModule",
  components: {},
  mixins: [QueryMixins],
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      newCargoArrivedTime: this.order.cargoArrivedStart,
      imgList: [],
      selectedRowKeys: [],
      isorter: {
        prop: "createTime",
        order: "asc",
      },
      queryForm: {},
      saveDisabled: false,
      time: 5,
      buttonContent: '保存',
      loading: false,
      data: [],
      editStartOptions: {
        disabledDate: (time) => {
          if (!this.form.geteTime) {
            // time = new Date(time.replace(/-/g, '/'));
            return time.getTime() < new Date(1970 - 1 - 1).getTime(); //禁止选择1970年以前的日期
          } else {
            return time.getTime() > new Date(this.form.geteTime);
          }
        },
      },
      editStopOptions: {
        disabledDate: (time) => {
          // time = new Date(time.replace(/-/g, '/'));
          return (
              time.getTime() < new Date(this.form.getsTime) ||
              time.getTime() < new Date(1970 - 1 - 1).getTime() //禁止选择1970年以前的日期
          );
        },
      },
      dialogFormVisible: false,
      form: {realCtn: "", realKg: "", storehouseRemark: "", data: []},
      formLabelWidth: "120px",
      btnDisable: false,
      row: {},
      visible: false,
      //表单校验
      rules: {
        contact: [
          {required: true, message: "请选择联系人", trigger: "change"},
        ],
        getsTime: [
          {required: true, message: "请选择提货开始时间", trigger: "change"},
        ],
        geteTime: [
          {required: true, message: "请选择提货结束时间", trigger: "change"},
        ],
        contactAdd: [
          {required: true, message: "请输入地址", trigger: "blur"},
        ],
        contactTel: [
          {required: true, message: "请输入手机号", trigger: "blur"},

          // {
          //   pattern: mobilePattern,
          //   message: "请输入正确的手机号",
          //   trigger: "blur",
          // },
        ],
        realCtn: [
          {required: true, message: '请输入实际总件数', trigger: 'blur'},
          {pattern: /^(0|[1-9]\d*)$/, message: '只能输入整数', trigger: 'blur'},
          {validator: validateCtn2, trigger: "change"}
        ],
        realKg: [
          // {required: true, message: '请输入实际总重量', trigger: 'blur'},
          {validator: validateCmb, trigger: "change"}
        ],
        number: [{required: true, message: '不能为空', trigger: 'change'},
          {validator: validateCtn, trigger: "change"}],
        number2: [{validator: validateCtn2, trigger: "change"}],
        trailerKg: [{validator: validateCmb, trigger: "change"}],
        length: [{required: true, message: '请输入长', trigger: 'blur'}],
        width: [{required: true, message: '请输入宽', trigger: 'blur'}],
        height: [{required: true, message: '请输入高', trigger: 'blur'}],
        sanweiCanNull: [{validator: validateCmb, trigger: 'change'}],
        sanweiCanNotNull: [
          {required: true, message: '不能为空', trigger: 'blur'},
          {validator: validateCmb, trigger: 'change'}
        ],

      },

      url: {
        list: "/interface/ttOrder/getVolumeList",
        save: "/interface/ttOrder/updateVolumeInfo",
        updateFeeNum: "/interface/ttOrder/updateVolumeCountFeeNum",
        delete: '/interface/ttOrder/deleteOneVolumeById',
        deleteVolumeByIdList: '/interface/ttOrder/deleteVolumeByIdList',
        //导出材积
        downloadVolumeList: '/file/jasper/download/volume/list',
        getImage: '/interface/warehouse/getWarehouseImage',
        updateCargoArrivedTime: '/interface/ttOrder/updateCargoArrivedTime',
        updateVolumeLocation:"/interface/ttOrder/updateVolumeLocation",
        queryOssFilesByNo:"/warehouse/pda/queryOssFilesByNo"

      },
      obj: {},
      tihuoList: [],
      supplierlist: [],
      suploading: false,
      suppliermap: {},
      cusId: "",
      editingKey: '',
      keyTotal: '',
      //总长
      lengthTotal: '',
      //总宽
      widthTotal: '',
      //总高
      heightTotal: '',
      //总件数
      numberTotal: '',
      //总重量
      weightTotal: '',
      //总体积重
      totalVolumeWeight: 0,
      //总重量体
      totalWeightVolume: 0,
      //总计费重
      totalCountFeeWeight: 0,
      //总计费体
      totalCountFeeVolume: 0,
      countFeeNum: '',
      cmbTotal: '',
      headeInpLen: ['length', 'width', 'height', 'number', 'weight', 'volume', 'largeInfo'],
      tableRefresh: false,
      allLocation: [],
      totalLocations: [],
      showColume: false,
      queryCount:1,
      visible:false,
      formWarehouse:{},
       rulesWarehouse:{
                     volumeLocationId: [
                        {required: true, message: '请选择货物所在仓', trigger: 'blur'},

                    ],
                },
      disableMixinMounted:true,
      fileList:[],
      deliveryPointDictList:[],
      imgVisible:false,
      takeImgList:[]
    };
  },
  created() {
    this.queryForm.orderNo = this.$route.query.orderNo;
    // this.queryForm.thStatus = "0";
    this.initDocpage();

  },
  methods: {
    handleUpdateCargoArrivedTime() {
      this.$store.commit("setFullscreenLoading", true);
      postAction(this.url.updateCargoArrivedTime, {orderNo: this.$route.query.orderNo, cargoArrivedTime: this.order.cargoArrivedStart, newCargoArrivedTime: this.newCargoArrivedTime,isCheck:'1'})
        .then((res) => {
          this.$store.commit("setFullscreenLoading", false);
          this.$emit("init","volume");
        }).catch(err => {
          this.$store.commit("setFullscreenLoading", false);
        })
    },
    headerClass({row, column, rowIndex, columnIndex}) {
      if (['实重(KG)', '体积重(KG)', '单箱计费重(KG)'].indexOf(column.label) !== -1) {
        return 'text-align: center; fontSize: 13px; color: #2d8cf0'
      } else if (['实体(CBM)', '重量体(CBM)', '计费体(CBM)'].indexOf(column.label) !== -1) {
        return 'text-align: center; fontSize: 13px; color: green'
      } else if (['订单编号', '计费数量'].indexOf(column.label) !== -1) {
        return 'text-align: center; fontSize: 13px; color: green'
      }
    },
    downloadExcel(url, fileName, params) {
      getAction(url, params, {responseType: 'blob'})
          .then(response => {
            if (!response) {
              return
            }
            const blob = new Blob([response])
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName)
            } else {
              let u = window.URL.createObjectURL(response)
              let aLink = document.createElement('a')
              aLink.style.display = 'none'
              aLink.href = u
              aLink.setAttribute('download', fileName + '.xls')
              document.body.appendChild(aLink)
              aLink.click()
              document.body.removeChild(aLink)
              window.URL.revokeObjectURL(u)
            }
          })
    },
    downloadVolumeList() {
      let orderNo = this.$route.query.orderNo;
      let fileName = orderNo + '-材积导出'
      this.downloadExcel(this.url.downloadVolumeList, fileName, {orderNo: orderNo, t: 'excel'});
    },
    //批量删除材积
    deleteBatch() {
        if(this.selectionRows.some(item=>item.volumeLocation==='转仓中')){
    this.$message.warning('转仓中状态不支持批量删除');
return
              }
      this.$confirm("确定删除材积?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
        closeOnClickModal: false,
      }).then(() => {
        postAction(this.url.deleteVolumeByIdList, this.selectedRowKeys)
            .then(res => {
              this.$message.success(res.message);
              this.forceByOrder();
              this.calcVolAndCalc();
            })
      })
    },
    locationChange(val) {
      // console.log('locationChange',val)
      if (val.length === 0) {
        this.allLocation = {...this.totalLocations}
      } else {
        // console.log('选择库位更改显示的仓库',val)
        let fi = this.totalLocations.filter(location => {
          return location.warehouseLocationCode === val
        })
        // this.form.warehouse = fi[0].warehouse
        // console.log('选的仓库',fi)
        this.$set(this.form, 'warehouse', fi[0].warehouse);
        this.$set(this.form, 'warehouseId', fi[0].warehouseId);
        this.$set(this.form, 'warehouseId', fi[0].warehouseId);
        // this.allLocation = this.totalLocations.filter(i =>{return i.warehouseId === fi[0].warehouseId})

      }

    },
    edit(key) {
      const newData = [...this.form.data];
      const target = newData.filter(item => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.form.data = newData;
      }
    },
    handleAdd() {
      if (this.form.data.length === 0) {
        let nkey = 1
        this.form.data.push({
          key: nkey,
          length: '',
          width: '',
          height: '',
          number: '',
          weight: '',
          volume: '',
        });
        this.keyTotal = nkey
        this.edit(nkey)

      } else {
        let num = this.form.data.length
        let nextKey = num + 1
        this.form.data.push({
          key: nextKey,
          length: '',
          width: '',
          height: '',
          number: '',
          weight: '',
          volume: '',
        });

        this.edit(nextKey)
      }
      let num = this.form.data.length
      this.keyTotal = num + '条'
    },
    handleDel(key) {
      const obj = this.form.data[key - 1]
      let that = this
      if (obj.hasOwnProperty('id')) {
        this.$confirm('确定删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error',
          closeOnClickModal: false,
        }).then(() => {
          getAction(that.url.delete, {'id': obj.id}).then(res => {
            if (res.success) {
              that.$message.success(res.message);
              that.form.data.splice(key - 1, 1)
              that.calcVolAndCalc();
              that.handleQuery()
              this.$emit("init",'volume2');
              // this.forceByOrder();
            }
          })
        }).catch(() => {
        });
      } else {
        that.form.data.splice(key - 1, 1)
        that.calcVolAndCalc()
      }


    },
    nextFocus(i, idx, e) {
      let that = this;
      console.log('i->', i)
      console.log('idx->', idx)
      let inputArray = document.getElementsByClassName("classi");  //通过class去获取
      let size = this.headeInpLen.length;
      //判断第几行
      let rowNum = i * (size - 1)
      console.log('第几行->', rowNum)
      //判断第几个input
      let inputLineNum = this.headeInpLen.indexOf(idx);
      console.log('第几个input->', inputLineNum)

      //最后一个input 再点就要换行
      if (inputLineNum >= (size - 1) || inputLineNum == -1) {
        // 计算换行后聚焦第几个
        rowNum = (i + 1) * size - i;
        inputLineNum = rowNum;
      } else {
        inputLineNum = rowNum + inputLineNum + 1;
      }
      if (inputLineNum + i === inputArray.length) {
        return
      }
      let nextSelect = inputArray[i + inputLineNum];
      let nextInput = inputArray[i + inputLineNum].childNodes;

      console.log('第节点->', i + inputLineNum, nextInput, nextSelect)

      //去打印nextInput就会发行我们要的节点在第二个，所以去取nextInput[1]
      let sel = that.$refs['selectLargeInfo.' + i];
      if (idx === 'volume') {
        // nextSelect.focus();
        // nextInput[1].focus();
        // nextInput[2].focus();
        // console.log("是否获取到下拉框",sel);
        if (sel) {
          sel.focus();
        }
      } else {
        nextInput[1].focus();
        if (sel) {
          sel.blur();
        }
      }

      console.log('-------------------')

    },
    calcVolAndCalc() {
      let lgt = 0
      let wdt = 0
      let hgt = 0
      let nbt = 0
      let cbt = 0
      let wgt = 0
      this.form.data.forEach((obj, index) => {
        obj.key = index + 1
        if (obj.length !== '') {
          lgt = lgt + parseFloat(obj.length)
        }
        if (obj.width !== '') {
          wdt = wdt + parseFloat(obj.width)
        }
        if (obj.height !== '') {
          hgt = hgt + parseFloat(obj.height)
        }
        if (obj.number !== '') {
          nbt = nbt + parseInt(obj.number)
        }
        if (obj.volume !== '') {
          cbt = cbt + parseFloat(obj.volume)
        }
        if (obj.weight !== '') {
          wgt = wgt + parseFloat(obj.weight)
        }
      })
      this.lengthTotal = lgt.toFixed(2)
      this.widthTotal = wdt.toFixed(2)
      this.heightTotal = hgt.toFixed(2)
      this.numberTotal = nbt
      this.cmbTotal = cbt.toFixed(3)
      this.weightTotal = wgt.toFixed(2)
      let num = this.form.data.length
      this.keyTotal = num + '条'
      this.tableRefresh = !this.tableRefresh
    },
    handleCopy(record) {
      var num = this.form.data.length - 1
      var nextKey = this.form.data[num].key + 1
      this.form.data.push({
        key: nextKey,
        length: record.length,
        width: record.width,
        height: record.height,
        number: record.number,
        volume: record.volume,
        weight: record.weight,
        largeInfo: record.largeInfo,
        volumeWeight: record.volumeWeight,
        weightVolume: record.weightVolume,
        countFeeWeight: record.countFeeWeight,
        countFeeVolume: record.countFeeVolume,
      });
      const newData = [...this.form.data];
      const target = newData.filter(item => nextKey === item.key)[0];
      this.editingKey = nextKey;
      if (target) {
        target.editable = true;
        this.form.data = newData;
      }
      this.calcVolAndCalc()


    },
    handleChange(value, key, column, index) {
      this.form.data[index][column] = value
      let cul = column
      let lgt = 0;
      let wdt = 0;
      let hgt = 0;
      let nbt = 0;
      let cbt = 0;
      let wgt = 0;
      let volumeWeight = 0;
      let weightVolume = 0;
      let countFeeWeight = 0;
      let countFeeVolume = 0;

      this.form.data.forEach((obj, indexobj) => {
        if (cul === 'volumeWeight') {
          if (obj.volumeWeight !== '') {
            volumeWeight = volumeWeight + parseFloat(obj.volumeWeight)
          }
          this.totalVolumeWeight = parseFloat(volumeWeight).toFixed(2)
        }
        if (cul === 'weightVolume') {
          if (obj.weightVolume !== '') {
            weightVolume = weightVolume + parseFloat(obj.weightVolume)
          }
          this.totalWeightVolume = parseFloat(weightVolume).toFixed(2)
        }
        if (cul === 'countFeeWeight') {
          if (obj.countFeeWeight !== '') {
            countFeeWeight = countFeeWeight + parseFloat(obj.countFeeWeight)
          }
          this.totalCountFeeWeight = parseFloat(countFeeWeight).toFixed(2)
        }
        if (cul === 'countFeeVolume') {
          if (obj.countFeeVolume !== '') {
            countFeeVolume = countFeeVolume + parseFloat(obj.countFeeVolume)
          }
          this.totalCountFeeVolume = parseFloat(countFeeVolume).toFixed(2)
        }
        if (cul === 'length') {
          if (obj.length !== '') {
            lgt = lgt + parseFloat(obj.length)
          }
          this.lengthTotal = parseFloat(lgt).toFixed(2)
        }
        if (cul === 'width') {
          if (obj.width !== '') {
            wdt = wdt + parseFloat(obj.width)
          }
          this.widthTotal = wdt.toFixed(2)
        }
        if (cul === 'height') {
          if (obj.height !== '') {
            hgt = hgt + parseFloat(obj.height)
          }
          this.heightTotal = hgt.toFixed(2)
        }
        if (cul === 'number') {
          if (obj.number !== '') {
            nbt = nbt + parseInt(obj.number)
          }
          this.numberTotal = nbt
        }
        if (cul === 'weight') {
          if (obj.weight !== '') {
            wgt = wgt + parseFloat(obj.weight)
          }
          this.weightTotal = wgt.toFixed(2);
          this.$set(this.form,'realKg',this.weightTotal);
        }
        if (cul === 'volume') {
          if (obj.volume !== '') {
            cbt = cbt + parseFloat(obj.volume)
          }
          this.cmbTotal = cbt.toFixed(3)
        } else if (indexobj === index) {
          let real_cmb = (obj.length * obj.width * obj.height * obj.number) / 1000000;
          real_cmb = real_cmb.toFixed(3);
          obj.volume = real_cmb

          if (obj.volume !== '') {
            cbt = Number(cbt) + Number(obj.volume)
          }
          this.cmbTotal = cbt.toFixed(3)
        } else {
          if (obj.volume !== '') {
            cbt = Number(cbt) + Number(obj.volume)
          }
          this.cmbTotal = cbt.toFixed(3)
        }
      })
      let num = this.form.data.length
      this.keyTotal = num + '条'
    },
    checkboxClassName({row, column, rowIndex, columnIndex}) {
      if (row.thStatus !== "0") {
        return "tb_cell";
      }
    },
    updateSup(name, index) {
      if (name) {
        if (this.suppliermap[name]) {
          let obj = this.suppliermap[name];
          this.form.tableData[index].contactAdd = obj.address;
          this.form.tableData[index].contactTel = obj.tel;
        }
      }
    },
    initDocpage() {
      //页面一加载完成，根据订单号获取数据 渲染在头部
      let orderNo = this.$route.query.orderNo;
      console.log("订单号", orderNo);
      if (orderNo) {
        this.handleQuery();
      }
      this.$emit('init','volume3')
    },
    initSupplier() {
      this.supplierlist = [];
      this.suploading = true;
      //获取发货人列表
      getAction(this.url.getSupplierList, {cusId: this.cusId})
          .then((res) => {
            if (res.success) {
              let list = res.result;
              for (var i = 0; i < list.length; i++) {
                let value = list[i].name;
                let label = list[i].name;
                this.supplierlist.push({value, label});
              }
              this.suppliermap = this.listToMap(list, "name");
              this.suploading = false;
            } else {
              this.$message.warning("获取被保人列表失败");
              this.suploading = false;
            }
          })
          .catch((err) => {
            this.suploading = false;
          });
    },
    handleSearch() {
      this.ipagination.currentPage = 1;
      this.handleQuery();
    },
    getImage(params) {
      this.imgList = [];
      this.queryCount = this.queryCount + 1;
      getAction(this.url.getImage, {id: params.orderNo,queryCount:this.queryCount})
          .then(res => {
             this.fileList = res.result;
            if (this.fileList && this.fileList.length > 0) {
              this.imgList = this.fileList.map(s => s.fileDownloadUrl);
            }
          })
    },
    async handleQuery() {
      this.loading = true;
      let form = {...this.queryForm, ...this.filterForm};
      for (let item in form) {
        if (typeof form[item] === "string") {
          form[item] = form[item].trim();
          if (form[item] === "") {
            form[item] = null;
          }
        }
      }
      const {prop, order} = this.isorter;
      // 传入的参数
      const params = {
        ...form,
        column: prop,
        order: order.indexOf("asc") !== -1 ? "asc" : "desc",
      };
      //判断 是否要查询startTime

      if (!params.column) {
        if (this.queryForm.column === "startTime") {
          this.$set(params, "column", "startTime");
        } else {
          this.$set(params, "column", "createTime");
        }
      }
      if (this.ipagination) {
        params.pageNo = this.ipagination.currentPage;
        params.pageSize = this.ipagination.pageSize;
      }
      console.log("查询对象", params);
      this.getImage(params);
      await getAction(this.url.list, params)
          .then((res) => {
            if (res.success) {

              this.form.data = res.result.volumes || res.result.volumes2 || [];
              console.log("表单数据", this.form.data);
              this.deliveryPointDictList = res.result.deliveryPoints;
              this.$set(this.form, 'realCtn', res.result.ctn)
              this.$set(this.form, 'realKg', res.result.realKg)
              this.$set(this.form, 'realCmb', res.result.realCmb)
              this.$set(this.form, 'storehouseRemark', res.result.storehouseRemark)
              this.$set(this.form, 'tempWareHouseLocations', res.result.tempLocation.join(","))
              this.$set(this.form, 'location', res.result.location.join(","))
              this.$set(this.form, 'warehouse', res.result.warehouse)
              this.$set(this.form, 'warehouseId', res.result.warehouseId)
              this.$set(this.form, 'allVolumeLocations', res.result.allVolumeLocations)
              this.countFeeNum = res.result.countFeeNum
              this.showColume = res.result.showColume
              console.log("先" + this.countFeeNum)
              let list = res.result.allLocation;
              if (list && list.length > 0) {
                for (let i = 0; i < list.length; i++) {
                  let obj = list[i];
                  //{{ item.warehouse + " - " + item.warehouseLocationCode }}
                  obj.label = list[i].warehouse + "-" + list[i].warehouseLocationCode;
                }
              }
              this.$set(this.form, 'allLocation', list)
              this.totalLocations = list;
              this.allLocation = list;
            }
            console.log("handleQuery方法请求成功");
          })
          .finally(() => {
            this.loading = false;
            this.calcVolAndCalc();
          });
    },
    sendTihuo() {
      //校验
      let ids = this.form.tableData.map(t => {
        if (t.thStatus === '0') {
          return t.id;
        }
      }).join(',');
      if (!ids || ids.length <= 0) {
        this.$message.warning('没有提货数据可以申请呢亲！');
        return false;
      }
      putAction(this.url.sendTihuo + "?ids=" + ids)
          .then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.handleQuery();
            }
          })
    },

    async updateFeeNum() {
      console.log('updateFeeNum')
      let parm = {
        orderNo: this.$route.query.orderNo,
        countFeeNum: this.countFeeNum,
      }
      console.log("后" + parm.countFeeNum)
      await postAction(this.url.updateFeeNum, parm)
          .then((res) => {
          })
    },

    forceByOrder() {
      this.handleQuery().then(res => {
        this.updateFeeNum().then(res => {
          this.$emit("init",'volume4');
        });
      })
    },

    saveBatch() {
      let that = this;

      this.$refs['form'].validate((err, values) => {

        if (err) {
          // 比较 过磅重 和 总实重
          let totalWeight = 0;
          if (that.form.data && that.form.data.length > 0) {
            for (let i = 0; i < that.form.data.length; i++) {
              let weight = that.form.data[i]['weight'];
              if (weight) {
                totalWeight += Number(weight);
              }
            }
            // debugger
            // console.log("过磅重和总实重",that.form.realKg,totalWeight);
            // if (Number(totalWeight) - Number(that.form.realKg) !== 0) {
            //       that.$message.warning('过磅重和总实体重要相等 请修正！');
            //       return false;
            // }
          }

          that.confirmLoading = true;
          let httpurl = that.url.save;
          let method = 'post';
          //去除实际件数不能大于录入的合计件数
          // if (that.numberTotal < this.form.realCtn) {
          //     that.$message.warning('实际件数不能大于录入的合计件数！');
          //     this.$refs.realt.focus()
          //     this.saveDisabled = false
          //     return;
          // }


          this.$confirm(that.numberTotal > this.form.realCtn ? '实际件数小于总件数是否进行保存?' : '是否确定保存？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'success',
            closeOnClickModal: false,
          }).then(() => {


            for (let i = 0; i < that.form.data.length; i++) {
              let ind = i + 1
              let obj = that.form.data[i]
              if (obj.length === '' && obj.width === '' && obj.height === '' && obj.number === '') {
              } else {
                // if (Number(obj.length) > 350) {
                //     that.$message.warning('第' + ind + '行长度不能超过350cm,请修改！');
                //     that.saveDisabled = false;
                //     return;
                // }
                // if (Number(obj.width) > 350) {
                //     that.$message.warning('第' + ind + '行宽度不能超过350cm,请修改！');
                //     that.saveDisabled = false;
                //     return;
                // }
                // if (Number(obj.height) > 350) {
                //     that.$message.warning('第' + ind + '行高度不能超过350cm,请修改！');
                //     that.saveDisabled = false;
                //     return;
                // }
                // if (obj.volume === 0 || obj.volume === '0.000') {
                //     that.$message.warning('第' + ind + '行，材积信息不正确！');
                //     that.saveDisabled = false;
                //     return;
                // }
              }
            }
            let location = [];
            let tempWareHouseLocations = [];
            if (this.form.location) {
              location.push(this.form.location);
            }
            console.log(this.form.location)
            // if (!this.form.tempWareHouseLocations){
            //     tempWareHouseLocations = [];
            // }
            if (this.form.tempWareHouseLocations) {
              tempWareHouseLocations.push(this.form.tempWareHouseLocations);
            }
            if (!this.form.warehouse) {
              this.form.warehouseId = '';
            }
            let mod = {
              id: that.id,
              orderNo: that.$route.query.orderNo,
              ctn: this.form.realCtn,
              realKg: this.form.realKg,
              storehouseRemark: this.form.storehouseRemark,
              location: location,
              tempLocation: tempWareHouseLocations,
              allLocation: [],
              // storehouseRemark: this.form.storehouseRemark,
              // fbaLable: this.form.fbaLable,
              // maker: this.form.maker,
              // productLable: this.form.productLable,
              // mark: this.form.mark,
              // cartons: this.form.cartons,
              realCmb: that.cmbTotal,
              volumes: this.form.data,
              volumes2: this.form.data,
              // numberTotal: that.numberTotal,
              // receiveBy: this.form.receiveBy,
              // dropBy: this.form.dropBy,
              warehouse: this.form.warehouse,
              warehouseId: this.form.warehouseId,
              countFeeNum: this.countFeeNum,
            }
            that.$store.commit("setFullscreenLoading", true);
            httpAction(httpurl, method, mod).then((res) => {
              if (res.success) {
                // that.$message.success(res.message);
                // this.$listeners.updataDemo();
                this.forceByOrder();
                that.$message.success(res.message);
              }

            }).finally(() => {
              that.$store.commit("setFullscreenLoading", false);
              that.confirmLoading = false;
              // that.changeSaveDisabled();
            })

          }).catch(() => {

          })

        } else {
          that.saveDisabled = false;
        }
      })
    },
    async changeSaveDisabled() {
      let clock = window.setInterval(() => {
        this.time--
        this.buttonContent = this.time + 's后可再次保存'
        if (this.time < 0) {     //当倒计时小于0时清除定时器
          window.clearInterval(clock)
          this.saveDisabled = false
          this.buttonContent = '保存'
          this.time = 5
        }
      }, 1000)
    },
    handleSelectionChange(selection) {
      // 多选
      let arr = [];
      this.tihuoList = [];

      for (let i = 0; i < selection.length; i++) {
        let {id, contactAdd, contact, contactTel, getsTime, geteTime} =
            selection[i];
        this.obj.id = id;
        this.obj.contactAdd = contactAdd;
        this.obj.contact = contact;
        this.obj.contactTel = contactTel;
        this.obj.getsTime = getsTime;
        this.obj.geteTime = geteTime;

        arr.push(selection[i]["id"]);

      }
      this.selectionRows = selection;
      this.selectedRowKeys = [...arr];
      console.log("选择的ids", this.selectedRowKeys.join(","));
      console.log("选择的提货信息", this.tihuoList);
    },
    handleReset() {
      this.queryForm = {};
      this.queryForm.cusId = this.$route.query.id;
      this.ipagination.currentPage = 1;

      this.handleQuery();
    },
    //显示 合计行
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '共' + this.keyTotal;
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        // if (column.property === "createTime") {
        //   sums[index] = "订单编号：" + this.$route.query.orderNo;
        //   return;
        // } else if (column.property === "createBy") {
        //   sums[index] = "计费数量：" + this.countFeeNum;
        //   return;
        // }
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (column.property === "length") {
            this.lengthTotal = sums[index].toFixed(3);
            sums[index] = "长：" + sums[index].toFixed(3);
          } else if (column.property === "width") {
            this.widthTotal = sums[index].toFixed(3);
            sums[index] = "宽：" + sums[index].toFixed(3);
          } else if (column.property === "height") {
            this.heightTotal = sums[index].toFixed(3);
            sums[index] = "高：" + sums[index].toFixed(3);
          } else if (column.property === "number") {
            this.numberTotal = sums[index].toFixed(0);
            sums[index] = "数量：" + sums[index].toFixed(0);
          } else if (column.property === "weight") {
            this.weightTotal = sums[index].toFixed(3);
            sums[index] = "实重：" + sums[index].toFixed(3);
            // 给过磅重 自动录入
            this.$set(this.form,'realKg',this.weightTotal)
          } else if (column.property === "volume") {
            this.cmbTotal = sums[index].toFixed(3);
            sums[index] = "实体：" + sums[index].toFixed(3);
          } else if (column.property === "volumeWeight") {
            this.totalVolumeWeight = sums[index].toFixed(3);
            sums[index] = "体积重：" + sums[index].toFixed(3);
          } else if (column.property === "weightVolume") {
            this.totalWeightVolume = sums[index].toFixed(3);
            sums[index] = "重量体：" + sums[index].toFixed(3);
          } else if (column.property === "countFeeWeight") {
            this.totalCountFeeWeight = sums[index].toFixed(3);
            sums[index] = "单箱计费重：" + sums[index].toFixed(3);
          } else if (column.property === "countFeeVolume") {
            this.totalCountFeeVolume = sums[index].toFixed(3);
            sums[index] = "计费体：" + sums[index].toFixed(3);
          } else {
            sums[index] = "";
          }
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    changetLocation(e){
                if(e && e.title){
                    this.formWarehouse.volumeLocation = e.title
                }else{
                    this.formWarehouse.volumeLocation = ''
                }
            },
    changeWarehouse () {
                this.$refs.formWarehouse.validate((valid) => {
                if(valid){
                     const params = {
                    ids:this.selectedRowKeys,
                    volumeLocationId:this.formWarehouse.volumeLocationId,
                    volumeLocation:this.formWarehouse.volumeLocation
                }
                postAction(this.url.updateVolumeLocation, params).then((res) => {
                    if(res.success){
                      this.handleQuery()
                        this.handleClose()
                    }
                });
  }
})
            },
     handleClose(){
                this.visible=false
            },
     changetLocations(e,index){
                if(e&&e.title){
                    this.form.data[index].volumeLocation = e.title
                }else{
                    this.form.data[index].volumelocation = ''
                }
            },
            changeWarehouseFn(){
              this.visible=true
//               if(this.selectionRows.some(item=>item.volumeLocation==='转仓中')){
//     this.$message.warning('转仓中状态不支持修改货物所在仓');
//               }else{
// this.visible=true
//               }
              
              
            },
            getImgList() {
               getAction(this.url.queryOssFilesByNo, {
                orderNo: this.$route.query.orderNo
               }).then((res) => {
                    if(res.success){
                      this.takeImgList=res.result
                      this.imgVisible=true
                    }else{
                       this.takeImgList=[]
                        this.$message.warning('暂无数据');
                    }
                });
          
            }


  },
  computed : {
    buttonShowList() {
      return {
        'bc':this.$btnIsShow('docpage/modules/volumeModule','0','保存'),
        'plsc':this.$btnIsShow('docpage/modules/volumeModule','0','批量删除'),
        'dccj':this.$btnIsShow('docpage/modules/volumeModule','0','导出材积'),
        'xgszc':this.$btnIsShow('docpage/modules/volumeModule','0','修改所在仓'),
        'bcdcsj':this.$btnIsShow('docpage/modules/volumeModule','0','保存到仓时间'),
        'shtp':this.$btnIsShow('docpage/modules/volumeModule','0','查看收货图片'),
        
      }
    }
  }
};
</script>
<style lang="scss">
.cont_box {
  width: 200px;
  height: 200px;
.el-image {
 
}
}
.take_img_box {
   & > :not(:last-child) {
      margin-right: 10px;
    }
  img{
    width: 120px;
    height: 120px;
  }
}

.button_bottom_o {
  .el-button {
    margin-bottom: 0 !important;
  }
}
.totalBox {
  .length .el-input__inner {
    width: 225px;
  }

  .ctn .el-input__inner {
    width: 85px;
  }
}

.nowrap_label {
  .el-form-item__label {
    white-space: nowrap;
  }
}
</style>
<style lang="scss" scoped>
.totalFirst {
  width: 50px;
  text-align: center;
}

.totalBox {
  padding-top: 10px;
}

.volume_top {
  margin-top: 10px;
}
</style>
